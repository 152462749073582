<template>
  <div
    id="editor"
    :style="{ width: width, height: height }"
  />
</template>

<script>
import loader from "@monaco-editor/loader"
export default {
	name: 'MonacoEditor',
	props: {
		value: {
			type: String,
			default: ''
		},
		width: {
			type: String,
			default: '100%'
		},
		height: {
			type: String,
			default: '100%'
		},
		language: {
			type: String,
			default: 'html'
		},
		minimap: {
			type: Object,
			default() {
				return {
					enabled: false
				}
			}
		}
	},
	data() {
		return {
			editor: null
		}
	},
	methods: {
		loadEditor() {
			loader.init().then((monaco) => {
				const editorOptions = {
					value: this.value,
					language: this.language,
					minimap: this.minimap,
					formatOnPaste: true,
					formatOnType: true
				}
				this.editor = monaco.editor.create(document.getElementById("editor"), editorOptions)
				monaco.editor.getModels()[0].onDidChangeContent(() => {
					this.$emit('input', monaco.editor.getModels()[0].getValue())
				})
			})
		}
	},
	mounted() {
		this.loadEditor()
  },
	beforeDestroy() {
		if(this.editor) {
			this.editor.dispose()
		}
	},
	watch: {
		value(value) {
			if(this.editor) {
				this.editor.setValue(value)
			}
		}
	}
}
</script>
